.topWinnersContainer {
  &.desktop {
    padding: 30px 22px;
  }
  &.mobile {
    padding: 0 5px;
    margin-top: 20px;
  }
  .buttonsContainer {
    border-radius: 25px;
    border: 1px solid var(--appYellow);

    .tabButtons {
      border-radius: 22px;
    }
  }

  .contentWrapper {
    background: var(--appBlue);
  }

  //.tabButtons {
  //  border-radius: 0;
  //  border: 1px solid transparent;
  //}
  //
  //.firstButtonRadius {
  //  border-top-left-radius: 22px;
  //  border-bottom-left-radius: 22px;
  //}
  //
  //.secondButtonRadius {
  //  border-top-right-radius: 22px;
  //  border-bottom-right-radius: 22px;
  //}
  //
  //.notSelectedBorder {
  //  border: 1px solid var(--appYellow);
  //}

  .headerTitlesContainer {
    //margin-left: 27px;
    //width: calc(100% - 54px);
  }

  .topWinnersRowItem {
    //width: calc(100% - 54px);
  }

  .topWinnersSwiper {
    margin: 0;

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 10px;
      font-weight: bold;
      color: var(--white);
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: var(--appBlue);
    }
  }

  .noDataContainer {
    min-height: 100px;
  }
}
