.liveIndicator {
  &_1 {
    width: 34px;
    height: 19px;
    background-color: #ed4001;
    &_title {
      color: white;
      font-size: 13px;
    }
  }
  &_2 {
    width: 28px;
    height: 17px;
    background-color: #ed4001;
    &_title {
      color: white;
      font-size: 10px;
    }
  }
}
