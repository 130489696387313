.privacyPageContainer {
  .privacyPolicyPageSwiper {
    .swiper-slide {
      width: fit-content;
    }
  }
  .homeHeaderContainer {
    .homeHeaderLogo {
      cursor: pointer;
    }
  }
  .headerFiltersContainer {
    background-color: var(--appBlue);

    .betSlipCounter {
      top: -5px;
      right: 3px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: var(--appYellow);
      border: 1px solid var(--appBlue);
    }

    .headerFiltersSwiper {
      .swiper-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-slide {
        width: fit-content;
      }
    }
  }

  .mainMenu {
    min-width: 80px;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .menuOpened {
    background-color: var(--appYellow);
  }

  .appBetSlipWrapper_mobile {
    top: 40px;
    z-index: 16;
    height: 100dvh;
    position: fixed;
    align-items: center;
    transition: transform 0.5s ease-in-out;

    &.slide-up {
      transform: translateY(0);
    }
    &.slide-down {
      transform: translateY(110%);
    }
  }
}

.depositContainer {
  border-radius: 5px;
  background-color: var(--lightenBlue);

  &.mobile {
    padding: 2px 5px;
  }

  &.desktop {
    height: 30px;
    min-width: 90px;
    //padding: 0 25px;
  }
}

.balanceDropDown_opened {
  border-radius: 5px;
  background-color: var(--appYellow);
}

.appBetSlipWrapper {
  .betSlipDesktopWrapper {
    background: #194478;
  }
}

.userBalanceText {
  overflow: hidden;
  text-overflow: ellipsis;

  &.mobile {
  }

  &.desktop {
  }
}
