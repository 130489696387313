.trapezoid {
  width: 15px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  &_1 {
    border-bottom: 4px solid var(--semiLightBlue);
  }

  &_2 {
    border-bottom: 4px solid var(--white);
  }
}
