.appSelect {
  z-index: 10;

  .dropdown-btn {
    cursor: pointer;
    font-size: 13px;

    &_1 {
      height: 45px;
      margin-bottom: 3px;
      background: var(--appBlue);
      border: 1px solid var(--appYellow);

      &_active {
        background: var(--appYellow);
      }
    }

    &_2 {
      background: var(--lightPurple);
    }

    &_3 {
      background: var(--appYellow);
    }

    &_4 {
      height: 35px !important;
      background: var(--white);
    }

    &_5 {
      height: 35px !important;
      background: rgb(0, 48, 105);
    }

    &_6 {
      background: #021f4b;
      height: 35px !important;
      border: 1px solid var(--appYellow);
    }

    &_7 {
      gap: 5px;
      color: white;
      height: 45px;
      background: #003069;

      &_active {
        background: var(--appYellow);
      }
    }

    &_8 {
      gap: 5px;
      color: white;
      height: 45px;
      background: var(--appBlue);

      &_active {
        background: var(--appYellow);
      }
    }

    &.disabled {
      background-color: var(--darkGray);
    }
  }

  .dropdown-content {
    left: 0;
    font-size: 13px;
    max-height: 200px;

    &_1 {
      color: var(--white);
      background: var(--appBlue);

      .item {
        height: 30px;
        cursor: pointer;
        font-size: 12px;
        margin-bottom: 3px;
        &.selected {
          color: var(--appBlue);
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--lightBlue);
        }
      }

      .item:hover {
        color: var(--appBlue);
        background: var(--appYellow);
      }

      &_yellowContainer {
        padding: 5px;
        height: 150px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 2px solid var(--appYellow);
        border-right: 2px solid var(--appYellow);
        border-bottom: 2px solid var(--appYellow);

        &_wrapper {
          height: 98%;
          padding-right: 4px;
        }
      }
    }

    &_2 {
      color: var(--appBlue);
      background: var(--lightPurple);
      .item {
        cursor: pointer;
        &.selected {
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--lightPurple);
        }
      }

      .item:hover {
        background: var(--appYellow);
      }
    }

    &_3 {
      border-radius: 2px;
      color: var(--appBlue);
      background: var(--white);
      border: 2px solid var(--appYellow);
      .item {
        cursor: pointer;
        &.selected {
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--white);
        }
      }
    }

    &_4 {
      border-radius: 2px;
      color: var(--appBlue);
      background: var(--white);
      .item {
        cursor: pointer;
        &.selected {
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--white);
        }
      }
    }

    &_5 {
      border-radius: 2px;
      color: var(--white);
      background: rgb(0, 48, 105);
      .item {
        cursor: pointer;
        &.selected {
          background-color: rgb(0, 48, 105);
        }

        &.no-selected {
          background: rgb(0, 48, 105);
        }
      }
    }

    &_6 {
      font-size: 10px;
      max-height: 200px;
      border-radius: 2px;
      color: var(--white);
      background: #021f4b;
      .item {
        cursor: pointer;
        &.selected {
          background: #021f4b;
        }

        &.no-selected {
          background: #021f4b;
        }
      }
    }

    &_7 {
      color: var(--white);
      background: var(--appBlue);
      .item {
        //height: 30px;
        cursor: pointer;
        font-size: 12px;
        max-height: 45px;
        &.selected {
          color: var(--appBlue);
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--lightBlue);
        }
      }

      .item:hover {
        color: var(--appBlue);
        background: var(--appYellow);
      }

      &_yellowContainer {
        padding: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid var(--appYellow);
        border-right: 1px solid var(--appYellow);
        border-bottom: 1px solid var(--appYellow);

        &_wrapper {
          height: 98%;
          padding-right: 4px;
        }
      }
    }

    &_8 {
      color: var(--white);
      background: var(--appBlue);
      .item {
        //height: 30px;
        cursor: pointer;
        font-size: 12px;
        max-height: 45px;
        &.selected {
          color: var(--appBlue);
          background-color: var(--appYellow);
        }

        &.no-selected {
          background: var(--lightBlue);
        }
      }

      .item:hover {
        color: var(--appBlue);
        background: var(--appYellow);
      }

      &_yellowContainer {
        padding: 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid var(--appYellow);
        border-right: 1px solid var(--appYellow);
        border-bottom: 1px solid var(--appYellow);

        &_wrapper {
          height: 98%;
          padding-right: 4px;
        }
      }
    }
  }

  .checkedIcon {
    top: 0;
    width: 18px;
    right: 15px;
    height: 100%;
    position: absolute;
  }
}
