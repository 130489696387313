.appBetSlipContainer {
  //max-width: 95vw;
  &.mobile {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    //max-height: 90dvh;
    //border-radius: 15px;
  }

  &.desktop {
    //height: calc(100vh - 210px);
  }

  .mobileBetSlipHeader {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .betSlipContentWrapper {
    &.mobile {
      //min-height: 80dvh;
    }
  }

  .enterCodeContainer {
    height: 150px;
    border-radius: 10px;
    background: var(--white);
  }
}
.overlay {
  top: 0;
  left: 0;
  z-index: -1;
  width: 200vw;
  height: 100dvh;
  position: fixed;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
}
