.gamesActionBarContainer {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--semiLightGray);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    height: 10px;
    background: var(--darkBlue);
  }

  .disabledReset {
    background-color: var(--lightPink);
  }

  .mobileSize {
    height: 30px !important;
  }

  .searchInputContainer {
    flex: 1;

    .searchedGamesContainer {
      z-index: 22;
      max-height: 220px;
      background-color: var(--white);
    }
  }

  .leaguesButtonContainer {
    width: 115px;
    max-width: 200px;
  }

  .leaguesButton {
    height: 100%;
    border: 1px solid var(--darkGray);
  }

  .regionsWrapper {
    top: 45px;
    z-index: 16;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    box-shadow: 2px 7px 5px var(--semiLightGray);
  }

  .searchContainer {
    z-index: 22;
    max-height: 220px;
    background: var(--white);
  }
}

.searchModalContainer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 88;
  background-color: rgba(40, 71, 106, 0.5);

  .mobileWrapper {
    top: 5px;
    width: 98% !important;
  }

  .wrapper {
    .gamesContainer {
      border-radius: 5px;
      //background-color: var(--white);
    }

    .resetButton {
      height: 35px;
      width: 100px;
      border-radius: 5px;
    }

    .clearSearchButton {
      width: 100px;
      height: 30px;
    }

    .resentSearchButton {
      width: fit-content;
    }

    .listContainer {
      max-height: 50dvh;
    }

    .teamNameContainer {
      &:hover {
        background: var(--appYellow);
      }
    }
  }
}

.regionsModalContainer {
  height: 60dvh;

  .regions {
    height: 60dvh;
  }

  .regionItemContainer {
    .arrowIcon {
      width: 10px;
      height: 10px;
    }
  }

  .halfWidth {
    width: 70px;
  }

  .competitionsContainer {
    height: 60dvh;
  }
}

.flex {
  display: flex;
}

.container {
  //width: SCREEN_WIDTH * 0.95;
  width: 100%;
}
.headerContainer {
  padding: 5px;
  flex: 1;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
}
.contentContainerStyle {
  gap: 5px;
  display: flex;
  padding-top: 10px;
  flex-direction: column;
}
.searchedGamesContainerWrapper {
  padding-left: 12px;
  padding-right: 12px;
}
.searchedGamesContainer {
  max-height: 100%;
}
.sportContainer {
  gap: 5px;
}
.searchContainer {
  gap: 5px;
  padding-left: 5px;
}
.searchContentContainer {
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid var(--appYellow);
  background-color: var(--semiDarkBlue);
}
.listContainer {
  padding-top: 10px;
}
.searchTitle {
  font-size: 15px;
  font-weight: 600;
}
.closeContainer {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: white;
}
.row {
  flex-direction: row;
}
.center {
  align-items: center;
  justify-content: center;
}
.wrapperPadding {
  padding-left: 12px;
  padding-right: 12px;
}

.resultsFoundContainer {
  gap: 10px;
  padding-top: 24px;
  align-items: center;
}
.yourLastSearchesContainer {
  gap: 5px;
  margin-top: 17px;
  margin-bottom: 9px;
  align-items: center;
}
.yourLastSearches {
  font-size: 11px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}
.sportsContainer {
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
.topTeamsContainer {
  height: 290px;
}
.resetContainer {
  height: 40px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: white;
}

.backIcon {
  //transform: rotate(-90deg);
}

.sportItemContainer {
  .container {
    gap: 2px;
    display: flex;
    padding-top: 5px;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    flex-direction: column;
    background-color: #193a61;
  }

  .wrapper {
    gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .teamContainer {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .team2Container {
    gap: 3px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .teamLogo {
    width: 21px;
    height: 21px;
  }

  .smallTeamLogo {
    width: 11px;
    height: 11px;
  }

  .teamName {
    color: white;
    font-size: 10px;
    font-weight: 600;
    // color: Colors.white;
    // maxWidth: singleName ? SCREEN_WIDTH / 3 : SCREEN_WIDTH - 100;
  }

  //teamName: (singleName = true) => ({
  //  fontSize: 12;
  //  fontWeight: 600;
  //  // color: Colors.white;
  //  color: 'white';
  //  // maxWidth: singleName ? SCREEN_WIDTH / 3 : SCREEN_WIDTH - 100;
  //});

  .gameInfoContainer {
    color: white;
    font-size: 9px;
    margin-top: 2px;
    font-weight: 400;
  }
}

.filterItem {
  min-width: 60px;
  margin-left: 3px;
  border-radius: 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  flex-direction: row;
  align-items: center;
  padding-right: 11px;
  padding-left: 11px;
  justify-content: center;
  background-color: #193a61;
}
.filterTitle {
  color: white;
  font-size: 12px;
  font-weight: 600;
}
.activeFilterButton {
  background-color: var(--appYellow);
}
.activeFilterTitle {
  color: var(--appBlue);
}

.swiperContainer {
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.swiper-slide {
  width: fit-content;
}
