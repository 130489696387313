.casinoActionBarContainer {
  .lastPlayedContainer {
    height: 20px;

    .image {
      width: 10px;
      height: 10px;
    }
  }

  .mobileSize {
    height: 30px !important;
  }
}

.casinoSearchedItemContainer {
  border-radius: 5px;

  &:hover {
    background: var(--appYellow);
  }
  .searchedItemImage {
    width: 25px;
    height: 25px;
  }
}
