.gameEventsTimelineContainer {
  .lineupsContainer {
    height: 70px;
    background-size: cover;
    background-image: url('../../../../Assets/Icons/Globals/grass.png');
  }
  .timeLineSeparators {
    height: 30px;
    width: 100%;
    .line {
      width: 1px;
      height: 12px;
      background: #ffffff;
    }
    .periodMarker {
      top: -25px;
      width: 14px;
      height: 10px;
      color: #4c8f42;
      position: relative;
      border-radius: 2px;
      background: rgba(255, 255, 255, 0.6);
      &.halfTime {
        top: -15px;
      }
    }
    .currentTimeProgress {
      height: 18px;
      border-radius: 1px;
      position: absolute;
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
