.featuredGamesContainer {
  .regionImage {
    width: 15px;
    height: 10px;
  }

  .featuredGamesSwiperContainer {
    .swiper-slide img {
      border-radius: 10px;
    }

    .swiper-slide {
      opacity: 1;
      width: 80%;
      max-width: 500px;
      transition: opacity 0.7s;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 14px;
      font-weight: bold;
      color: var(--white);
    }
  }

  .mobileFeaturedGamesSwiperContainer {
    .swiper-slide-prev,
    .swiper-slide-next {
      opacity: 0.5;
    }
  }

  .featuredTitleContainer {
    border-radius: 5px;
    background-color: rgb(219, 225, 250);
  }

  .featuredGameItemContainer {
    min-height: 180px;
    border-radius: 5px;
    background-color: var(--white);

    .blueLine {
      width: 1.5px;
      height: 10px;
      background-color: var(--appBlue);
    }

    .competitionName {
      //max-width: 110px;
    }

    .grayLine {
      height: 1px;
      background-color: var(--lightgray);
    }

    .priceContainer {
      flex: 1;
      text-align: center;
      border-radius: 5px;
      background-color: var(--appBlue);
    }
  }
}

.webFeaturedGamesSwiperContainer {
  .swiper-wrapper {
    //margin-left: 25px;
  }
}
