.balanceManagementContainer {
  //border-bottom: 2px solid var(--appYellow);
  //border-right: 2px solid var(--appYellow);
  //border-left: 2px solid var(--appYellow);
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--darkenGray);
  }

  .balanceCardsSwiper {
    .swiper-slide {
      width: fit-content;
    }
  }

  .itemIcon {
    top: -15px;
    right: -15px;
    opacity: 0.5;
    position: absolute;
  }

  .nameContainer {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .cardContainer {
    height: 90px;
    border-radius: 10px;
  }

  .tabsSwiper {
    margin: 0;

    .swiper-slide {
      width: fit-content;
      //width: 148px !important;
    }
  }
}
