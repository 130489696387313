.mainBettingContainerWrapper {
  overscroll-behavior: none;
  min-height: 300px;
  &.loggedIn {
    //max-height: calc(100vh - 265px);
  }
  &.loggedOut {
    //max-height: calc(100vh - 225px);
  }

  .copyBetButton {
    width: 150px;
  }
}
