.competitionsContainer {
  border-radius: 5px;
  background: var(--white);

  .line {
    width: 100%;
    height: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: var(--gray);
  }

  .name {
    max-width: 135px;
  }

  .count {
    max-width: 50px;
  }
}

.gamesActionBarContainer {
  .competitionsBlock {
    top: 50px;
    left: 200px;
    z-index: 16;
    border-radius: 5px;
    background-color: var(--white);
  }

  .competitionsWrapper {
    min-width: 200px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 5px;
    background-color: var(--white);
  }
}

.sportItemContainer {
  .competitionsBlock {
    top: 100px;
    left: 245px;
    z-index: 11;
    border-radius: 5px;
    background-color: var(--white);
  }

  .competitionsWrapper {
    min-width: 200px;
    max-height: 400px;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--white);
  }
}
