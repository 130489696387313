.liveCalendarFilters {
  .matchItem {
    padding-left: 15px;
    padding-right: 15px;
  }
  .buttons {
    height: 30px;
  }
  .liveCalendarFiltersSwiper {
    &.desktop {
      margin: 0 auto;
      max-width: 100%;
      width: fit-content !important;
    }

    &.mobile {
      margin-left: 0;
      max-width: 100%;
      //border-radius: 25px;
      padding: 0 !important;
      width: fit-content !important;
      //border: 1px solid var(--appYellow);

      .swiper-wrapper {
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
    }

    &.wrapperBorders {
      //border-radius: 17px;
      //border: 1px solid var(--appYellow);
    }

    .swiper-slide {
      width: fit-content;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}
