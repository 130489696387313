.selfExclusionTabContainer {
  min-height: 65dvh;
  .line {
    width: 100%;
    height: 1px;
    background-color: var(--darkenGray);
  }
  .content {
    //min-height: 50dvh;
  }
}
