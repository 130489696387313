.transactionHistoryFilters {
  .transactionHistoryFiltersSwiper {
    margin: 0;

    .swiper-slide {
      width: fit-content;
    }
  }
  .transactionTypeTitle {
    width: 170px;
  }
}
