.toggle-switch {
  position: relative;
  display: inline-block;

  &_1 {
    width: 19px;
    height: 11px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(8px);
      background-color: var(--white);
    }
  }

  &_2 {
    width: 49px;
    height: 23px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(25px);
      background-color: var(--white);
    }
  }

  &_3 {
    width: 38px;
    height: 21px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(17px);
      background-color: var(--white);
    }
  }

  &_4 {
    width: 38px;
    height: 21px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(17px);
      background-color: var(--appYellow);
    }
    input[type='checkbox']:checked + .switch {
      background-color: var(--sapphireBlue) !important;
    }
  }

  &_5 {
    width: 30px;
    height: 15px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(14px);
      background-color: var(--white);
    }
  }

  &_6 {
    width: 53px;
    height: 26px;
    input[type='checkbox']:checked + .switch::before {
      transform: translateX(27px);
      background-color: var(--white);
    }
  }

  .switch {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    border-radius: 25px;
    transition: background-color 0.2s ease;

    &_1 {
      background-color: var(--darkenBlue);
    }

    &_2 {
      background-color: var(--darkenGray);
    }

    &_3 {
      background-color: var(--darkenGray);
    }

    &_4 {
      background-color: var(--gray);
    }

    &_5 {
      background-color: var(--white) !important;
    }

    &_6 {
      background-color: #003069 !important;
    }

    &_1::before {
      top: 1px;
      left: 1px;
      width: 9px;
      height: 9px;
    }

    &_2::before {
      top: 1px;
      left: 2px;
      width: 21px;
      height: 21px;
    }

    &_3::before {
      top: 1px;
      left: 1px;
      width: 19px;
      height: 19px;
    }

    &_4::before {
      top: 1px;
      left: 1px;
      width: 19px;
      height: 19px;
      background-color: var(--appYellow) !important;
    }

    &_5::before {
      top: 2px;
      left: 2px;
      width: 11px;
      height: 11px;
      background-color: var(--appYellow) !important;
    }

    &_6::before {
      top: 2px;
      left: 2px;
      width: 22px;
      height: 22px;
      background-color: var(--appYellow) !important;
    }

    &::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: var(--white);
      transition: transform 0.3s ease;
    }
  }

  input[type='checkbox']:checked + .switch {
    background-color: var(--lightGreen);
  }

  input[type='checkbox'] {
    display: none;
  }
}
