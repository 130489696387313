.betHistoryContainer {
  &.rowType {
  }

  .bodyCotainer {
    max-height: 300;
  }

  .rowTypeBody {
    height: 550px;
  }

  .logoContainer {
    &.rowType {
      flex: 1;
    }
  }
}
