.messagesContainer {
  min-height: 65dvh;
  .unReadMessagesCounterContainer {
    top: 3px;
    right: 5px;
    width: 12px;
    height: 12px;
    position: absolute;
    border-radius: 50%;
  }
  .messagesItemsSwiper {
    .swiper-slide {
      width: fit-content;
    }
  }
}
