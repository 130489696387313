.pokerGameContainer {
  .disabledGame {
    opacity: 0.7;
  }
  .mobileHeaderContainerWrapper {
    width: 100%;
  }

  .iframe-holder {
    //width: 100%;
    background-color: red !important;
  }

  .pokerGameIframe {
    border: 0;
    width: 100%;

    &.mobile {
      height: calc(100dvh - 135px);
    }

    &.desktop {
      height: calc(100dvh - 134px);
    }

    &.fullScreen {
      height: calc(100dvh - 50px);
    }
  }

  .betslipToggleButton {
    right: 0;
    z-index: 105;
    border-top-left-radius: 65px;
    border-bottom-left-radius: 65px;
    top: calc(50% - 110px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 27px;
    height: 54px;
    border: 1px solid rgba(white, 0.2);
    border-right: 0;
    box-shadow: 0 0.2rem 1.9rem 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(white, 0.05);
    backdrop-filter: blur(1px);
  }

  .headerToggleButton {
    top: 40px;
    border-bottom-right-radius: 65px;
    border-bottom-left-radius: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 54px;
    height: 27px;
    border: 1px solid rgba(white, 0.2);
    border-top: 0;
    box-shadow: 0 0.2rem 1.9rem 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(white, 0.05);
    backdrop-filter: blur(1px);
  }

  .headerToggleButton_open {
    top: 0;
    border-bottom-right-radius: 65px;
    border-bottom-left-radius: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 54px;
    height: 27px;
    border: 1px solid rgba(white, 0.2);
    border-top: 0;
    box-shadow: 0 0.2rem 1.9rem 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(white, 0.05);
    backdrop-filter: blur(1px);
  }
}
