.mainInfoWrapper {
  &.container {
  }

  .logo {
    width: 120px;
  }

  .registerAnAccountText {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: var(--lightGreen);
  }

  .innerBlock {
    width: 100%;
  }

  .codeContainer {
    height: 45px;
    border-radius: 5px;
    background: var(--appYellow);
  }

  .passwordContainer {
    padding-top: 5px;
  }

  .continueContainer {
    padding-top: 15px;
  }

  .alreadyHaveAnAccount {
    font-size: 12px;
    padding-top: 10px;
    color: var(--appBlue);
  }

  .register {
    color: var(--appBlue);
  }

  .affiliatesText {
    font-size: 11px;
    font-weight: 700;
    border-radius: 3px;
    color: var(--appBlue);
    padding: 1px 20px 2px 20px;
    background: var(--appYellow);
  }

  .affiliatesImage {
    width: 40px;
    height: 40px;
  }

  .languageContainerWrapper {
    padding-bottom: 10px;
  }

  .affiliatesImageContainer {
  }

  .stepBlock_active {
    width: 15px;
    height: 15px;
    background: var(--appYellow);
  }

  .stepBlock {
    width: 15px;
    height: 15px;
    background: var(--semiLightGray);
  }

  .bottomContainer {
    padding-top: 15px;
  }
}
