.appRadioButton {
  &_1 {
    &.radio-label {
      color: #303030;
      font-size: 14px;
      cursor: pointer;
      font-weight: 400;
      margin-right: 7px;
      -webkit-tap-highlight-color: transparent;
    }

    .radio-input {
      margin: 0;
      visibility: hidden;
    }

    .radio-input:checked + span {
      border: 2px solid var(--semiBlue);
    }

    .radio-input:checked + span:after {
      opacity: 1;
    }

    .custom-radio {
      width: 16px;
      height: 16px;
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      display: inline-block;
      background-color: var(--appBlue);
      border: 2px solid var(--semiBlue);
    }

    /* for inner filled circle */
    .custom-radio::after {
      top: 50%;
      left: 50%;
      opacity: 0;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      transition: opacity 0.2s;
      background: var(--appYellow);
      transform: translate(-50%, -50%);
    }
  }

  &_2 {
    &.radio-label {
      color: #021f4b;
      font-size: 12px;
      cursor: pointer;
      font-weight: 500;
      padding: 9px 15px;
      background: white;
      margin-right: 7px;
      border-radius: 20px;
      -webkit-tap-highlight-color: transparent;
      &.checked {
        background: var(--appYellow);
      }
    }

    .radio-input {
      margin: 0;
      visibility: hidden;
    }

    .radio-input:checked + span:after {
      opacity: 1;
    }

    .radio-input:checked + .custom-radio {
      border: 1px solid transparent;
    }

    .custom-radio {
      width: 12px;
      height: 12px;
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      display: inline-block;
      border: 1px solid #1c3d70;
      background-color: transparent;
    }

    /* for inner filled circle */
    .custom-radio::after {
      top: 50%;
      left: 50%;
      opacity: 0;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      background: #00ba00;
      transition: opacity 0.2s;
      transform: translate(-50%, -50%);
    }
  }
}
