.signInWrapper {
  .logo {
    width: 80px;
    height: 40px;
  }

  .codeContainer {
    height: 45px;
    border-radius: 5px;
    color: var(--appBlue);
    background: var(--appYellow);
  }

  .passwordContainer {
    padding-top: 5px;
  }

  .resetHere {
    font-size: 12px;
    color: var(--appBlue);
  }

  .signInContainer {
    width: 100%;
  }

  .newTo {
    font-size: 12px;
    color: var(--appBlue);
  }

  .register {
    color: var(--appBlue);
  }

  .affiliatesText {
    font-size: 11px;
    font-weight: 700;
    border-radius: 3px;
    color: var(--appBlue);
    padding: 1px 20px 2px 20px;
    background: var(--appYellow);
  }

  .affiliatesImage {
    width: 40px;
    height: 40px;
  }

  .languageContainerWrapper {
    padding-bottom: 10px;
  }
}
