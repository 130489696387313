.homePageContainer {
  .sportsWrapper {
    top: -2px;
    z-index: 10;
    position: sticky;
    background-color: var(--appBlue);
  }
  .gamesContainer {
  }
}
