.appCarousel {
  box-shadow: 0 4px 4px 0 #00000040;

  .swiper-slide img {
    width: 100%;
    border-radius: 5px;
  }

  .swiper-slide {
    opacity: 1;
    width: 80%;
    transition: opacity 0.7s;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.5;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 14px;
    font-weight: bold;
    color: var(--white);
  }
}

.casinoCarousel {
}
