.teamsGeneralStatsContainer {
  z-index: 8;
  min-height: 105px;
  background: #ffffff14;
  //background-image: url('../../../../Assets/Layout/soccerWild.jpeg');

  //&:before {
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  z-index: -1;
  //  content: '';
  //  border-radius: 5px;
  //  position: absolute;
  //  background: rgba(0, 0, 0, 0.72);
  //}
  .statItem {
    width: 25px;
  }
}
