@import '../variables.sass'

\:root
  @each $name, $size in $sizes
    #{"--q-size-"}#{$name}: #{$size}

.fit
  width: 100% !important
  height: 100% !important
.full-height
  height: 100% !important
.full-width
  width: 100% !important
  margin-left: 0 !important
  margin-right: 0 !important

.window-height
  margin-top: 0 !important
  margin-bottom: 0 !important
  height: 100vh !important
.window-width
  margin-left: 0 !important
  margin-right: 0 !important
  width: 100vw !important

.block
  display: block !important
.inline-block
  display: inline-block !important

@each $space, $value in $spaces

  .#{$prefix}pa-#{$space}
    padding: map-get($value, "y") map-get($value, "x")
  .#{$prefix}pl-#{$space}
    padding-left: map-get($value, "x")
  .#{$prefix}pr-#{$space}
    padding-right: map-get($value, "x")
  .#{$prefix}pt-#{$space}
    padding-top: map-get($value, "y")
  .#{$prefix}pb-#{$space}
    padding-bottom: map-get($value, "y")
  .#{$prefix}px-#{$space}
    padding-left: map-get($value, "x")
    padding-right: map-get($value, "x")
  .#{$prefix}py-#{$space}
    padding-top: map-get($value, "y")
    padding-bottom: map-get($value, "y")

  .#{$prefix}ma-#{$space}
    margin: map-get($value, "y") map-get($value, "x")
  .#{$prefix}ml-#{$space}
    margin-left: map-get($value, "x")
  .#{$prefix}mr-#{$space}
    margin-right: map-get($value, "x")
  .#{$prefix}mt-#{$space}
    margin-top: map-get($value, "y")
  .#{$prefix}mb-#{$space}
    margin-bottom: map-get($value, "y")
  .#{$prefix}mx-#{$space}
    margin-left: map-get($value, "x")
    margin-right: map-get($value, "x")
  .#{$prefix}my-#{$space}
    margin-top: map-get($value, "y")
    margin-bottom: map-get($value, "y")

.#{$prefix}mt-auto, .#{$prefix}my-auto
  margin-top: auto
.#{$prefix}ml-auto
  margin-left: auto
.#{$prefix}mb-auto, .#{$prefix}my-auto
  margin-bottom: auto
.#{$prefix}mr-auto
  margin-right: auto
.#{$prefix}mx-auto
  margin-left: auto
  margin-right: auto
