.selectedCompetitionsContainer {
  .selectedCompetitionsSwiper {
    .swiper-slide {
      width: fit-content;
    }
    .swiper-button-prev {
      left: 0;
      background: linear-gradient(
        -90deg,
        rgba(2, 39, 81, 0) 0%,
        #022751 88.31%
      );
      &:after {
        left: 5px;
      }
    }

    .swiper-button-next {
      right: 0;
      background: linear-gradient(90deg, rgba(2, 39, 81, 0) 0%, #022751 88.31%);
      &:after {
        right: 5px;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 22px;
      &:after {
        font-size: 10px;
        font-weight: bolder;
        border-radius: 50%;
        background: white;
        width: 33px;
        height: 33px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #021f4b;
      }
      height: 100%;
      width: 116px;
    }
  }
}
