.forgotPasswordContainer {
  height: 450px;
}

.codeContainer {
  height: 45px;
  border-radius: 5px;
  color: var(--appBlue);
  background: var(--appYellow);
}
