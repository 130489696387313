.lobbyGamesContainer {
  .lobbyGamesContainerSwiper {
    .swiper-slide {
      width: fit-content;
      padding: 5px 3px 5px 3px;
    }

    .buttonContainer {
      border-radius: 10px;
      max-width: 110px !important;
      &:hover {
        scale: 1.05;
        transition: 0.4s;
      }

      .img {
        width: 100%;
        padding: 2px;
        object-fit: contain;
        border-radius: 10px;
        background: var(--semiBlue);
        max-width: 110px !important;
      }
    }

    .viewMoreButton {
      background-color: var(--darkBlue);
    }
  }
}
