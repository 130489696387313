.appFooter {
  .footerBorder {
    border-bottom: 1px solid #ffffff10;
  }
  .paymentIcon {
    opacity: 0.6;
    border-radius: 5px;
  }
  .linksBlock {
    width: 45%;
  }
  .paymentsblock {
    width: 60%;
  }
}
