.casinoSideBarProvidersContainer {
  width: 310px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .searchProvider::placeholder {
    font-size: 12px;
    font-weight: 700;
    color: var(--appBlue);
  }

  .filteredProvidersContainer {
    padding: 5px;
    margin-bottom: 10px;
  }

  .loaderContainer {
    min-height: 100dvh;
    //padding-left: 11px;
  }

  .actionBar {
    height: 68px;
    padding-left: 11px;
    padding-right: 18px;
    box-shadow: 4px 4px 4px 0px #00000040;

    &.mobile {
      //padding-right: 11px;
    }

    .arrowContainer {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
  }

  .providersContainer {
    padding-right: 13px;

    .showTypesContainer {
      padding: 9px 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .showTypeIcon {
        width: 35px;
        height: 35px;
      }
    }

    .yellowBorder {
      border: 2px solid var(--appYellow);
    }

    .providersListContainer {
      min-height: 300px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .wrapper {
      }
    }

    .allProvidersContainer {
      border-radius: 5px;
      //padding: 12px 10px;
    }

    .providerListItemContainer {
      padding: 12px 10px;
      border-radius: 5px;
    }

    .providerIconItemContainer {
      height: 50px;
      border-radius: 5px;
      width: calc(50% - 3px);

      .img {
        max-width: 80%;
        max-height: 60%;
        object-fit: contain;
      }
    }

    .allIcon {
      max-width: 50%;
      max-height: 50%;
      object-fit: contain;
    }
  }
}

.notOpenedRightArrowContainer {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #003069;
}
