.blinkingLiveIcon {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #009a18;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
