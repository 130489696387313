.regionsContainer {
  padding: 5px 10px;
  background-color: var(--white);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .regionItemContainer {
    .itemContainer {
      padding: 4px;
      border-radius: 5px;
    }

    .arrowIcon {
      width: 10px;
      height: 10px;
    }

    .image {
      width: 25px;
      height: 15px;
    }

    .title {
      max-width: 95px;
    }

    .line {
      width: 100%;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
      background-color: var(--gray);
    }
  }
}
