.messageItemContainer {
  .unChecked {
    border: 1px solid var(--appYellow);

    //background-color: var(--electricLightGreen);
  }

  .checked {
    //background-color: var(--lightGray);
  }
}
