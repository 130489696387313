.appLanguageChooserContainer {
  .languageContainer {
    padding: 4px;
    background: var(--semiDarkGray);

    &.background_1 {
      background: var(--semiDarkGray);
    }

    &.background_2 {
      background: var(--appBlue);
    }

    &.background_3 {
      height: 30px;
      background: #003069;
      .flag {
        width: 20px;
        height: 12px;
        object-fit: cover;
      }
    }

    .flag {
      width: 26px;
      height: 16px;
      object-fit: cover;
    }
  }
}

.languageItemContainer {
  width: 200px;
  height: 350px;
}
