.appTextArea {
  width: 100%;
  color: rgba(2, 31, 75, 0.3490196078);
  background: var(--white);
  border-radius: 5px;
  height: 400px;
  resize: none;
  outline: none;
  padding: 15px;
  font-size: 10px;
  border-width: 0;

  &::placeholder {
    font-size: 10px;
  }
}
