.checkbox_container {
  .checkbox {
    width: 15px;
    height: 15px;
    outline: none;
    cursor: pointer;
    appearance: none;
    border-radius: 3px;
    -webkit-appearance: none;

    &_1 {
      border: 0.15em solid var(--lightBlue);

      &:checked {
        position: relative;
        background-color: var(--appYellow);
        &::before {
          top: -3px;
          font-size: 14px;
          content: '\2713';
          font-weight: 900;
          position: absolute;
          color: var(--lightBlue);
        }
      }
    }

    &_2 {
      width: 9px;
      height: 9px;
      background: #003069;

      &:checked {
        position: relative;
        background-color: var(--appYellow);
        &::before {
          top: -2px;
          left: 1px;
          font-size: 9px;
          content: '\2713';
          font-weight: 900;
          position: absolute;
          color: var(--lightBlue);
        }
      }
    }

    &:hover {
      box-shadow: 0 0 3px var(--lightBlue);
    }
  }
}
