.circles {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #003069;
  //&_1 {
  //  border-bottom: 4px solid var(--semiLightBlue);
  //}
  //
  //&_2 {
  //  border-bottom: 4px solid var(--white);
  //}
}
