.closeIcon {
  top: 10px;
  z-index: 1;
  right: 10px;
  width: 15px;
  position: absolute;
}

.contentContainer {
  display: flex;
  overflow-y: auto;
}
