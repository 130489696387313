@import '../variables.sass'

.rounded-borders
  border-radius: $generic-border-radius
.rounded-top-borders
  border-top-left-radius: $generic-border-radius
  border-top-right-radius: $generic-border-radius
.rounded-bottom-borders
  border-bottom-left-radius: $generic-border-radius
  border-bottom-right-radius: $generic-border-radius
.border-radius-inherit
  border-radius: inherit

.no-transition
  transition: none !important

.transition-0
  transition: 0s !important

.glossy
  background-image: linear-gradient(to bottom, rgba(#fff, .3), rgba(#fff, 0) 50%, rgba(#000, .12) 51%, rgba(#000, .04)) !important

.#{$prefix}placeholder
  &::-webkit-input-placeholder
    color: inherit
    opacity: .7
  &::-moz-placeholder
    color: inherit
    opacity: .7
  &:-ms-input-placeholder
    color: inherit !important
    opacity: .7 !important
  &::-ms-input-placeholder
    color: inherit
    opacity: .7
  &::placeholder
    color: inherit
    opacity: .7

/* body */
.#{$prefix}body--fullscreen-mixin, .#{$prefix}body--prevent-scroll
  position: fixed !important

.#{$prefix}body--force-scrollbar-x
  overflow-x: scroll
.#{$prefix}body--force-scrollbar-y
  overflow-y: scroll

.#{$prefix}no-input-spinner
  -moz-appearance: textfield !important
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

.#{$prefix}link
  outline: 0
  text-decoration: none

  &--focusable:focus-visible
    text-decoration: underline dashed currentColor 1px

body.electron
  .#{$prefix}electron-drag
    //-webkit-user-select: none
    -webkit-app-region: drag
  .#{$prefix}electron-drag .#{$prefix}btn-item, .#{$prefix}electron-drag--exception
    -webkit-app-region: no-drag

img.responsive
  max-width: 100%
  height: auto
