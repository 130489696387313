.menu-balance-withdrawContainer {
  min-height: 440px;
  .paymentsHeaderContainer {
    width: 100px;
    background: var(--lightGray);
  }

  .paymentsSwiper {
    margin: 0;

    .swiper-slide {
      max-width: 100px;
      max-height: 46px;
    }

    .paymentSwiperImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &.not-selected {
        opacity: 0.6;
      }
    }
  }

  .not-selected {
    opacity: 0.6;
  }

  .casinoPaymentsSwiper {
    margin: 0;

    .swiper-slide {
      height: 47px !important;
      width: 101px !important;
    }

    .casinoPaymentSwiperImageContainer {
      &.not-selected {
        opacity: 0.6;
      }
      .casinoPaymentSwiperImage {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }
  }
}
