.appCallToActionContainer {
  z-index: 80;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  &.mobile {
    &.loggedIn {
      bottom: 46px;
    }
    &.loggedOut {
      bottom: 99px;
    }
  }

  &.desktop {
  }
  .extraBonusInfoContainer {
    left: 0;
    right: 0;
    top: -35px;
  }
  .extraBonusInfoText {
    border-radius: 10px;
    background: var(--darkPink);
  }
}
