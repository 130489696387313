.betHistoryItemContainer {
  .gameInfoContainer {
    background-color: var(--semiLightGray);
  }

  .eventInfoContainer {
    background-color: var(--lightGray);

    //&.dashed {
    //  border-bottom: 1px dashed var(--gray);
    //}
  }
}
