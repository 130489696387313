/*
 * Normalizing -- forked from Normalize.css v8
 */

*, *:before, *:after // @stylint ignore
  box-sizing: inherit
  -webkit-tap-highlight-color: transparent
  -moz-tap-highlight-color: transparent

html, body, #q-app
  width: 100%
  direction: ltr
// https://stackoverflow.com/a/23083463
body.platform-ios.within-iframe, body.platform-ios.within-iframe #q-app
  width: 100px
  min-width: 100%
html, body
  margin: 0
  box-sizing: border-box

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary
  display: block

/*
 * line 1: Remove the bottom border in Firefox 39-.
 * lines 2,3: Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title]
  border-bottom: none
  text-decoration: underline
  text-decoration: underline dotted

/**
 * Remove the border on images inside links in IE 10.
 */

img
  border-style: none

/*
 * line 1: Correct the inheritance and scaling of font size in all browsers.
 * line 2: Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
pre,
samp
  font-family: monospace, monospace
  font-size: 1em

/*
 * lines 1,2: Add the correct box sizing in Firefox.
 * line 3: Show the overflow in Edge and IE.
 */

hr
  box-sizing: content-box
  height: 0
  overflow: visible

/*
 * Change font properties to `inherit` in all browsers (opinionated).
 */

button,
input,
optgroup,
select,
textarea
  font: inherit
  font-family: inherit
  margin: 0

/*
 * Restore the font weight unset by the previous rule.
 */

optgroup
  font-weight: bold

/*
 * Show the overflow in IE.
 *  input: Show the overflow in Edge.
 *  select: Show the overflow in Edge, Firefox, and IE.
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 *  select: Remove the inheritance of text transform in Firefox.
 */

button,
input,
select
  overflow: visible
  text-transform: none

/*
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner, input::-moz-focus-inner
  border: 0
  padding: 0

/*
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring, input:-moz-focusring
  outline: 1px dotted ButtonText

/**
 * Correct the padding in Firefox.
 */

fieldset
  padding: 0.35em 0.75em 0.625em

/**
 * lines 1,3,4,6: Correct the text wrapping in Edge and IE.
 * line 2: Correct the color inheritance from `fieldset` elements in IE.
 * line 5: Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend
  box-sizing: border-box
  color: inherit
  display: table
  max-width: 100%
  padding: 0
  white-space: normal

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress
  vertical-align: baseline

/*
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea
  overflow: auto

/*
 * Remove the inner padding and cancel buttons in Chrome on OS X and
 * Safari on OS X.
 */

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration
  -webkit-appearance: none
