.accordionItemContainer {
  .headerContainer {
    //top: 35px; // size of group by comeptition block
    z-index: 1;
    position: sticky;
    background: var(--lightGray);
    &.mobile {
      //top: 166px;
    }
    &.desktop {
      //top: 182px;
    }
  }
}
