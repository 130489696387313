.mainMenuContainer {
}

.mainMenuListItem {
  border-radius: 5px;
}

.logOutButton {
  background-color: var(--appRed);
}

.copyBetButton {
  width: 52px;
  height: 20px;
  border-radius: 5px;
}
