.successContainer {
  width: 300px;
  height: 350px;

  .successIcon {
    height: 80%;
  }

  .titlesWrapper {
    width: 90%;
  }
}
