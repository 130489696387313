.filtersListContainer {
  .item {
    min-width: 100px;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }

  .swiper-slide {
    width: fit-content;
  }

  .swiper-button-prev {
    left: 0;
    background: linear-gradient(-90deg, rgba(2, 39, 81, 0) 0%, #022751 88.31%);

    &:after {
      left: 5px;
    }
  }

  .swiper-button-next {
    right: 0;
    background: linear-gradient(90deg, rgba(2, 39, 81, 0) 0%, #022751 88.31%);

    &:after {
      right: 5px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    &:after {
      font-size: 12px;
      font-weight: bolder;
      border-radius: 50%;
      background: white;
      width: 20px;
      height: 20px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #021f4b;
    }
    top: 20px;
    height: 100%;
    width: 124px;
  }
  .mobileCounter {
    top: 0;
    right: 5px;
  }
}
