@import '/src/Helpers/sass/index.sass';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &:not(input, textarea, select) {
    user-select: none;
  }
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  touch-action: pan-x pan-y !important;
  font-family: 'Montserrat', sans-serif;
}

iframe {
  border: 0;
}

.scroll {
  &-hidden {
    overflow: hidden;

    &-x {
      overflow-x: hidden;
    }
    &-y {
      overflow-y: hidden;
    }
  }

  &-auto {
    overflow: auto;

    &-x {
      overflow-x: auto;
    }
    &-y {
      overflow-y: auto;
    }
  }

  &-1 {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--semiLightGray);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      height: 10px;
      background: var(--darkBlue);
    }
  }

  &-2 {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--darkBlue);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      height: 10px;
      background: var(--semiLightGray);
    }
  }

  &-3 {
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: var(--darkBlue);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      height: 10px;
      background: var(--lightBlue);
    }
  }

  &-4 {
    &::-webkit-scrollbar {
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgb(22, 45, 79);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      opacity: 0.2;
      border-radius: 10px;
      background: rgb(57, 81, 109);
    }
  }

  &-5 {
    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff1a;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ffffff33;
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

:root {
  --gray: gray;
  --white: #fff;
  --black: #000000;
  --appRed: #ff0000;
  --appBlue: #022751;
  --darkBlue: #021f4b;
  --lightBlue: #194478;
  --appYellow: #ffca00;
  --lightenBlue: #012c5f;
  --newLightBlue: #003069;
  --lightBlack: #00000099;
  --semiDarkBlue: #022751;
  --electricGreen: #90ee90;
  --transparent: transparent;
  --aqua: rgb(165, 251, 218);
  --semiLightenGray: #b5b5b5;
  --purple: rgb(69, 32, 218);
  --orange: rgb(251, 106, 54);
  --darkPink: rgb(199, 0, 113);
  --darkenBlue: rgb(2, 31, 75);
  --darkGreen: rgb(10, 105, 9);
  --darkAqua: rgb(78, 157, 155);
  --semiBlue: rgb(66, 118, 207);
  --appPink: rgb(227, 104, 213);
  --darkGray: rgb(197, 208, 220);
  --lightGreen: rgb(26, 151, 30);
  --lightRed: rgb(233, 166, 179);
  --lightAqua: rgb(89, 186, 203);
  --lightGray: rgb(239, 239, 239);
  --sapphireBlue: rgb(60, 92, 155);
  --lightOrange: rgb(245, 159, 58);
  --darkenGray: rgb(195, 195, 195);
  --lightPurple: rgb(210, 218, 251);
  --lightenGreen: rgb(125, 192, 96);
  --semiDarkGray: rgb(225, 227, 231);
  --semiLightBlue: rgb(210, 218, 251);
  --semiLightGray: rgb(219, 221, 227);
  --defaultSkeletonHighlight: #f2f8fc;
  --defaultSkeletonBackground: #e1e9ee;
  --semiDarkenGray: rgb(190, 193, 205);
  --lightPink: rgba(253, 147, 150, 0.7);
  --inputBackground: rgb(204, 213, 221);
  --electricLightGreen: rgb(196, 250, 199);
  --disableBackground: rgba(0, 0, 0, 0.5);
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-15 {
  border-radius: 15px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.columnCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.columnCenterBetween {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.rowCenterBetween {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.blueText {
  color: var(--appBlue);
}

.blackText {
  color: var(--black);
}

.semiLightBlueText {
  color: var(--semiLightBlue);
}

.yellowText {
  color: var(--appYellow);
}

.whiteText {
  color: var(--white);
}

.grayText {
  color: var(--gray);
}

.lightGrayText {
  color: var(--lightGray);
}

.semiLightGrayText {
  color: #b5b5b5;
}

.redText {
  color: var(--appRed);
}

.orangeText {
  color: var(--orange);
}

.electricGreenText {
  color: var(--electricGreen);
}

.electricLightGreenText {
  color: var(--electricLightGreen);
}

.greenText {
  color: var(--lightGreen);
}

.blackBackground {
  background-color: var(--black);
}

.semiLightenGrayBackground {
  background-color: var(--semiLightenGray);
}

.newLightBlueBackground {
  background-color: var(--newLightBlue);
}

.newLightenBlueBackground {
  background-color: #174277;
}

.newExtraLightenBlueBackground {
  background-color: #104687;
}

.blueBackground {
  background-color: var(--appBlue) !important;
}

.darkenBlueBackground {
  background-color: var(--darkenBlue);
}

.lightenGreenBackground {
  background-color: var(--lightenGreen);
}

.darkBlueBackground {
  background-color: var(--darkBlue);
}

.semiLightBlueBackground {
  background-color: var(--semiLightBlue);
}

.yellowBackground {
  background-color: var(--appYellow);
}

.whiteBackground {
  background-color: var(--white);
}

.grayBackground {
  background-color: var(--gray);
}

.semiLightGrayBackground {
  background-color: var(--semiLightGray);
}

.darkenGrayBackground {
  background-color: var(--darkenGray);
}

.lightGrayBackground {
  background-color: var(--lightGray);
}

.softGrayBackground {
  background-color: #e6eaee;
}

.redBackground {
  background-color: var(--appRed);
}

.orangeBackground {
  background-color: var(--orange);
}

.greenBackground {
  background-color: var(--lightGreen);
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.boldText {
  font-weight: bold;
}

.bold-200 {
  font-weight: 200;
}

.bold-300 {
  font-weight: 300;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

.bold-800 {
  font-weight: 800;
}

.bold-900 {
  font-weight: 900;
}

.textWithDotes {
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.twoLineText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.threeLineText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.flex-1 {
  flex: 1;
}

.hide {
  display: none !important;
}

.absolute {
  position: absolute;
}

.resetLineHeight {
  line-height: 1px;
}

.relative {
  position: relative;
}

.loading > div {
  opacity: 0;
  visibility: hidden;
}

.loading {
  position: relative;
}

.loading::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  width: 16px;
  height: 16px;
  margin: auto;
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: var(--appBlue);
  animation: button-loading-spinner 1s ease infinite;
}

.whiteBorders {
  border: 1px solid white;
}

@for $i from 2 through 30 {
  .paddingVertical-#{$i} {
    padding-top: $i + px;
    padding-bottom: $i + px;
  }
}

@for $i from 2 through 30 {
  .paddingHorizontal-#{$i} {
    padding-left: $i + px;
    padding-right: $i + px;
  }
}

@for $i from 6 through 50 {
  .font-#{$i} {
    font-size: $i + px;
  }
}

@for $i from 1 through 50 {
  .gap-#{$i} {
    gap: $i + px;
  }
}

@for $i from 1 through 50 {
  .gap-row-#{$i} {
    row-gap: $i + px;
  }
}

@for $i from 1 through 50 {
  .gap-column-#{$i} {
    column-gap: $i + px;
  }
}

@for $i from 0 through 35 {
  .z-index-#{$i} {
    z-index: $i !important;
  }
}
