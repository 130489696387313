.detailedInfoWrapper {
  &.container {
  }

  .logo {
    width: 140px;
  }

  .innerBlock {
    width: 100%;
    row-gap: 10px;
  }

  .stepsContainer {
    padding-top: 20px;
    padding-bottom: 25px;
  }

  .stepBlock_active {
    width: 15px;
    height: 15px;
    margin: 0 10px;
    background: var(--appYellow);
  }

  .containerClass {
    gap: 3px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .containers {
    &:nth-child(2) {
      width: 40%;
    }
    width: 30%;
  }

  .selectClass {
    width: 100%;
    outline: none;
    font-size: 12px;
    padding: 13px 10px;
    border-radius: 5px;
    color: var(--white);
    background-size: 8px;
    appearance: none !important;
    background-repeat: no-repeat;
    -moz-appearance: none !important;
    background-color: var(--lightBlue);
    -webkit-appearance: none !important;
    background-position: calc(100% - 0.75rem) center !important;
    background-image: url('../../../../../Assets/Icons/Globals/downTriangleWhite.svg');
  }
}
