.homeProvidersSwiper {
  margin: 0;
  padding: 3px;
  width: fit-content;
  //border-radius: 25px;

  &.yellowBorder {
    //border: 1px solid var(--appYellow);
  }

  &.mobile {
    top: 0;
    position: sticky;
    margin-bottom: 7px;
  }
  .swiper-slide {
    width: fit-content;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 11px;
    font-weight: bold;
    color: var(--white);
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--appBlue);
  }
}

.subCategoriesSwiper {
  &.mobile {
    top: 0;
    position: sticky;
    .swiper-wrapper {
      padding-bottom: 5px;
    }
  }
  .swiper-slide {
    width: fit-content;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 11px;
    font-weight: bold;
    color: var(--white);
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--appBlue);
  }
}

.subCategoriesContainer {
  box-shadow: 0 4px 4px 0 #00000040;

  &.desktop {
    min-height: 68px;
    max-height: 68px;
  }

  &.mobile {
    margin-bottom: 10px;
    padding: 7px 10px 10px 10px;
  }

  .overlay {
    z-index: 17;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .mobileSideBarContainer {
    z-index: 33;
    transition: left 0.5s ease;

    .casinoSideBarProvidersContainer {
      .providersContainer {
        background-color: var(--appBlue);

        .providersListContainer {
          //height: calc(100vh - 175px);
        }
      }
    }
  }

  .mobileProvidersContainer {
    flex: 1;
    height: 30px;
  }

  .reset {
    border-radius: 5px;
  }

  .mobileLastPlayed {
    width: 103px;
    height: 30px;
    border-radius: 15px;
  }

  .mobileSearchContainer {
    height: 30px;
    padding: 0 10px;
    min-width: 125px;
    border-radius: 5px;

    &.mobile {
      height: 35px;
      padding: 0 5px;
      min-width: 230px;
      border-radius: 5px;
    }
  }

  .homeProvidersSwiperContainer {
    width: fit-content !important;
    .swiper-slide {
      width: fit-content;
    }
  }
}

.notOpenedRightArrowContainer {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #003069;
}
