.appBetSlipGameRow {
  &.deleted {
    background-color: var(--appRed);
  }

  .gameInfoContainer {
    background-color: #e6eaee;
  }

  .eventInfoContainer {
    background-color: var(--white);

    &.dashed {
      border-bottom: 1px dashed var(--gray);
    }
  }

  .deletedEventLayer {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.15;
    background-color: var(--appRed);
  }
}
