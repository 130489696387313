.mainMenuDropDownContainer {
  //left: 0;
  top: 30px;
  z-index: 90;
  right: 0;
  width: 270px;
  border-radius: 10px;
  background-color: #003069;
  //border-top-right-radius: 10px;
  //border-bottom-left-radius: 10px;
  //border-bottom-right-radius: 10px;
  border: 1px solid var(--appYellow);

  .mainMenuDropDownContainerHeader {
  }

  .line {
    //width: 100%;
    //height: 1px;
    //background-color: var(--semiLightGray);
  }

  .menuItemContainer {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .menuItem {
    width: 90px;
    margin: 0 auto;
    background: rgb(23, 66, 119);
  }
}
