.mobileLiveChatContainer {
  height: 65dvh;
  .overlay {
    top: 0;
    left: 0;
    z-index: -1;
    width: 200vw;
    height: 100vh;
    position: fixed;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
  }
}
