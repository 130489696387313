.inputContainerWrapper {
  .inputContainer {
    width: 100%;
    position: relative;
  }

  .input {
    width: 100%;
    outline: none;
    padding: 15px;
    border-width: 0;
    appearance: none;
    border-radius: 5px;
    position: relative;
  }

  .background_1 {
    color: var(--white);
    background: var(--lightBlue);
  }

  .background_2 {
    padding-top: 9px;
    padding-left: 45px;
    color: var(--gray);
    padding-bottom: 9px;
    background: var(--white);
  }

  .background_3 {
    color: var(--appBlue);
    background: var(--darkGray);
  }

  .background_4 {
    color: var(--appBlue);
    background: var(--white);
  }

  .background_5 {
    font-size: 10px;
    font-weight: 600;
    background: #003069;
    color: var(--appYellow);
    border: 1px solid transparent;
  }

  .background_5::placeholder {
    opacity: 1;
    font-size: 10px;
    color: #ffffff59;
  }

  .focused_5:focus {
    border: 1px solid var(--appYellow);
  }

  .background_6 {
    font-size: 11px;
    color: var(--white);
    background: #003069;
  }

  .background_7 {
    padding: 15px 0;
    color: var(--appBlue);
    background: var(--lightGray);
  }

  .background_8 {
    color: var(--white);
    background: #021f4b;
  }

  .background_9 {
    color: #022751;
    font-size: 12px;
    background: white;
  }

  .background_10 {
    color: #022751;
    font-size: 12px;
    padding-left: 0;
    background: white;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .background_1::placeholder {
    opacity: 1;
    font-size: 12.5px;
    color: var(--white);
  }

  .background_2::placeholder {
    opacity: 1;
    font-size: 12.5px;
    color: var(--gray);
  }

  .background_3::placeholder {
    opacity: 1;
    font-size: 12.5px;
    color: var(--appBlue);
  }

  .background_4::placeholder {
    font-size: 12px;
    font-weight: 700;
    color: var(--appBlue);
  }

  .background_7::placeholder {
    font-size: 13px;
    color: var(--appBlue);
  }

  .background_9::placeholder {
    font-size: 10px;
    color: #021f4b59;
  }

  .background_10::placeholder {
    color: #022751;
    font-size: 12px;
    font-weight: 500;
  }

  input:-webkit-autofill {
    transition:
      color 1s ease-in 2000s,
      background-color 1s ease-in 2000s;
  }

  .searchIconContainer {
    position: absolute;
    &_1 {
      top: 0;
      left: 0;
      width: 35px;
      height: 100%;
    }
    &_2 {
      top: 5px;
      left: 5px;
      width: 20px;
      height: 20px;
      border-radius: 5px;
      background: var(--appYellow);
    }
  }

  .leftSpaceWithSearch {
    // 35 is search width
    padding-left: 15px +15px;
  }

  .searchIcon {
    width: 18px;
  }

  .checkedIcon {
    width: 12px;
    right: 10px;
    height: 100%;
    position: absolute;
  }

  .downTriangle {
    width: 12px;
    right: 15px;
    height: 100%;
    position: absolute;
  }

  .eyeIcon {
    width: 18px;
    right: 15px;
    position: absolute;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
