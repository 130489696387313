.communityChatWidgetWrapper {
  .communityChatWidget {
    width: 60px;
    right: 13px;
    height: 60px;
    bottom: 21px;
    position: fixed;
    border-radius: 50%;
    z-index: 2147484247;
    background: var(--white);
    border: 2px solid var(--appBlue);
  }

  .communityChatIframeContainer {
    .communityChatIframeCloseIcon {
      top: 10px;
      left: 11px;
      z-index: 2147484247;
      position: absolute;
    }

    .communityChatIframe {
      z-index: 2147483640;
    }
  }
}
