.verifyAccountContainer {
  min-height: 65dvh;

  .verifyAccountSelect {
    //width: 120px !important;
  }

  input[type='file'] {
    display: none;
  }

  .custom-file-upload {
    cursor: pointer;
  }
}
