.betItemRowContainer {
  .topRadius {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .teamName {
    max-width: 200px;
  }
}

.betSlipSuccessModalContainer {
  max-height: 80dvh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 1px solid var(--appYellow);
  border-right: 1px solid var(--appYellow);
  border-bottom: 1px solid var(--appYellow);

  .listContainer {
    border-radius: 10px;

    .listWrapper {
      min-height: 250px;
    }
  }

  .shareYourBetNow {
    max-width: 90px;
    min-width: 90px;
  }

  .shareIcon {
    height: 42px;
  }
}
