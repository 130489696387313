.menu-balance-depositContainer {
  min-height: 440px;
  .paymentsHeaderContainer {
    width: 100px;
    background: var(--lightGray);
  }

  .paymentsSwiper {
    margin: 0;

    .swiper-slide {
      max-width: 100px;
      max-height: 46px;
    }

    .paymentSwiperImage {
      width: 100%;
      height: 100%;
      object-fit: contain;

      &.not-selected {
        opacity: 0.6;
      }
    }
  }
}
