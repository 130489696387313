.mobileHeaderContainerWrapper {
  //height: 50px;
  top: 0;
  z-index: 33;
  position: sticky;
  background-color: var(--white);
  box-shadow: 0 4px 4px 0 #00000040;

  .mobileHeaderContainer {
    .mainMenu {
      //min-width: 130px;
    }
    .mockDiv {
      width: 100px;
    }
    .fixedHeight {
      height: 40px;
    }
    .flagContainer::after {
      //top: 0;
      //left: 0;
      //right: 0;
      //bottom: 0;
      //content: '';
      //position: absolute;
      //background: linear-gradient(
      //  0.25turn,
      //  transparent -150%,
      //  var(--white) 75%
      //);
    }
    .depositContainerWrapper {
      //background: var(--appBlue);
    }

    .blueBackgroundLine {
      height: 3px;
      background-color: var(--appBlue);
    }

    .firstNameContainer {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .searchContainer {
      border-radius: 5px;
      background-color: var(--lightBlue);
    }
  }
  .betSlipCounter {
    top: -5px;
    right: 3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--appYellow);
    border: 1px solid var(--appBlue);
  }

  .filtersContainer {
    padding-bottom: 5px;
    background-color: var(--appBlue);
  }
}
