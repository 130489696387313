.float-left
  float: left
.float-right
  float: right

.relative-position
  position: relative

.fixed,
.fixed-full,
.fullscreen,
.fixed-center,
.fixed-bottom,
.fixed-left,
.fixed-right,
.fixed-top,
.fixed-top-left,
.fixed-top-right,
.fixed-bottom-left,
.fixed-bottom-right
  position: fixed

.absolute,
.absolute-full,
.absolute-center,
.absolute-bottom,
.absolute-left,
.absolute-right,
.absolute-top,
.absolute-top-left,
.absolute-top-right,
.absolute-bottom-left,
.absolute-bottom-right
  position: absolute

.fixed-top, .absolute-top
  top: 0
  left: 0
  right: 0
.fixed-right, .absolute-right
  top: 0
  right: 0
  bottom: 0
.fixed-bottom, .absolute-bottom
  right: 0
  bottom: 0
  left: 0
.fixed-left, .absolute-left
  top: 0
  bottom: 0
  left: 0

.fixed-top-left, .absolute-top-left
  top: 0
  left: 0
.fixed-top-right, .absolute-top-right
  top: 0
  right: 0
.fixed-bottom-left, .absolute-bottom-left
  bottom: 0
  left: 0
.fixed-bottom-right, .absolute-bottom-right
  bottom: 0
  right: 0

.fullscreen
  z-index: $z-fullscreen
  border-radius: 0 !important
  max-width: 100vw
  max-height: 100vh

body.#{$prefix}ios-padding .fullscreen
  padding-top: $ios-statusbar-height !important
  padding-top: env(safe-area-inset-top) !important
  padding-bottom: env(safe-area-inset-bottom) !important

.absolute-full, .fullscreen, .fixed-full
  top: 0
  right: 0
  bottom: 0
  left: 0

.fixed-center, .absolute-center
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.vertical-
  &top
    vertical-align: top !important
  &middle
    vertical-align: middle !important
  &bottom
    vertical-align: bottom !important

.on-left
  margin-right: 12px
.on-right
  margin-left: 12px

/* internal: */
.#{$prefix}position-engine
  margin-top: var(--q-pe-top, 0) !important
  margin-left: var(--q-pe-left, 0) !important
  will-change: auto
  visibility: collapse // needed for animation - is removed on first positioning
