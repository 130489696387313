.gameItemContainer {
  &.desktop {
    &:hover {
      //background: #ffe685;
    }
  }

  &.selectedBorder {
    border: 4px solid var(--appYellow);
  }

  .gameItemWrapper {
    flex-grow: 1;
  }

  .marketsCountContainer {
    .marketsCountWrapper {
      bottom: 5px;
    }
    .line {
      height: 1px;
      bottom: 10px;
      background-color: var(--lightGray);
    }
  }

  &.mobileBorderSeparator {
    border-bottom: 1px solid #021f4b26;
  }
  .statsContainer {
    &.desktop {
      &:hover {
        background-color: #f5df8c;
      }
    }
    &.percentWidth {
      width: 95%;
    }

    &.maxWidth {
      width: 98%;
    }

    .lockedOdds {
      right: 30px;
      bottom: 2px;
    }

    .teamNames {
      line-height: 1.25;
    }
  }

  .suspendedContainer {
    width: 70%;
    height: 48px;
    max-width: 200px;

    border-radius: 10px;
    background-color: var(--semiLightGray);
  }

  .rightDistance {
    right: 55px;
  }

  .smallScreenMarketsContainer {
    bottom: 0;
    right: 10px;
  }

  .selectedGameArrowContainer {
    width: 20px;
    right: -10px;
    height: 20px;
    border-radius: 10px;
    transform: rotate(180deg);
    background-color: var(--appYellow);
  }
}

.gamesDataContainer {
  .percentWidth {
    width: 95%;
  }

  .resultName {
    &.mobile {
      width: 50px;
    }

    &.desktop {
      width: 60px;
    }
  }

  .emptyView {
    width: 5%;
  }

  .blueLine {
    height: 3px;
  }

  .showMoreContainer {
    top: 150px;
  }

  .regionsWrapper {
    width: 240px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .competitionsWrapper {
    width: 240px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .groupByCompetitionContainer {
    position: sticky;
    border-top: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);

    .resetButton {
      width: 50px;
      &.desktop {
        left: 0;
        position: absolute;
      }
    }

    .leaguesButton {
      width: fit-content;
    }
  }
  .disabledReset {
    background-color: var(--lightPink) !important;
  }

  .flag {
    width: 15px;
    height: 10px;
  }
}

.suspendedBackground {
  background-color: var(--gray) !important;
}

.eventItemContainer {
  &.mobile {
    width: 55px;
    height: 45px;
    &.borderRadiusFirst {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.borderRadiusLast {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  &.desktop {
    width: 65px;
    height: 40px;
    &.betslip {
      background: #003069;
      width: 51px;
      height: 30px;
    }
  }

  &.blocked {
    background-color: var(--gray);
  }

  &.not-blocked,
  &.not-selected {
    background-color: var(--appBlue);
  }

  &.color-type-1 {
    color: var(--white);
  }

  &.color-type-2 {
    color: var(--appYellow);
  }

  &.color-type-3 {
    color: var(--appRed);
  }

  &.color-type-4 {
    color: var(--white);
    width: 45px !important;
    height: 39px !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    background: #021f4b !important;
    &.increased {
      color: #39ff14;
      font-size: 15px !important;
    }
    &.decreased {
      color: #f4007f;
      font-size: 15px !important;
    }

    &.selected {
      //color: var(--appBlue);
      background-color: var(--appYellow) !important;
    }
  }

  &.selected {
    color: var(--appBlue);
    background-color: var(--appYellow);
  }

  &.selectedBorders {
    border: 2px solid var(--appBlue);
  }

  .oddChangeIcon {
    right: 0;
    &.increased {
      bottom: 0;
      transform: rotate(135deg);
    }
    &.decreased {
      top: 0;
      transform: rotate(45deg);
    }
  }

  &.boostedOdds {
    width: 70px;
  }

  &.featuredGame {
    width: 100%;
    height: 45px;
    &.borderRadiusFirst {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &.borderRadiusLast {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.onCheckInUpcomingMatchesButton {
  min-width: 280px;
}
