.balanceDropDownContainer {
  left: 0;
  right: 0;
  top: 30px;
  z-index: 80;
  border-radius: 5px;
  background-color: var(--white);
  box-shadow: 0 0 5px 0 var(--black);

  .balanceDropDownContainerHeader {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--appYellow);
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: var(--semiLightGray);
  }
}
