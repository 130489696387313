.homeHeaderContainerWrapper {
  top: 0;
  z-index: 100;
  position: sticky;
  background-color: var(--darkBlue);

  .homeHeaderContainer {
    z-index: 1;
    box-shadow: 0 4px 4px 0 #00000040;
    .homeHeaderLogo {
      cursor: pointer;
    }
  }
  .headerFiltersContainer {
    background-color: var(--appBlue);
    box-shadow: 0 4px 4px 0 #00000040;

    .betSlipCounter {
      top: -5px;
      right: 3px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: var(--appYellow);
      border: 1px solid var(--appBlue);
    }

    .headerFiltersSwiper {
      .swiper-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .swiper-slide {
        width: fit-content;
      }
    }
  }

  .mainMenu {
    min-width: 80px;
    padding: 5px 10px;
  }

  .menuOpened {
    background-color: var(--appYellow);
  }
}

.depositContainer {
  border-radius: 5px;
  background-color: var(--lightenBlue);

  &.mobile {
    padding: 2px 5px;
  }

  &.desktop {
    height: 30px;
    min-width: 90px;
    //padding: 0 25px;
  }
}

.balanceDropDown_opened {
  border-radius: 5px;
  background-color: var(--appYellow);
}

.userBalanceText {
  overflow: hidden;
  text-overflow: ellipsis;

  &.mobile {
  }

  &.desktop {
  }
}
