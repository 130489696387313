.newFooterContainer {
  .bottomBorder {
    border-bottom: 1px solid #ffffff10;
  }

  &.loggedOutPadding {
    padding-bottom: 99px;
  }

  &.loggedInPadding {
    padding-bottom: 46px;
  }
}
