.counterContainer {
  border-radius: 50%;

  &_1 {
    width: 15px;
    height: 15px;
    background-color: var(--appYellow);
    &_active {
      background-color: var(--appBlue);
    }
  }

  &_3 {
    width: 10px;
    height: 10px;
    background-color: var(--appBlue);
    &_active {
      background-color: var(--white);
    }
  }

  &_2 {
    width: 15px;
    height: 15px;
    background-color: var(--lightGreen);
  }

  .title {
    &_1 {
      color: var(--appBlue);
      &_active {
        color: var(--appYellow);
      }
    }
    &_2 {
      color: var(--white);
    }
    &_3 {
      font-size: 6px;
      font-weight: 600;
      color: var(--white);
      &_active {
        color: var(--appBlue);
      }
    }
  }
}
