.casinoFixedGamesWrapper {
  .casinoFixedGamesSwiper {
    &.desktop {
      .swiper-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .swiper-slide {
      width: fit-content;
    }
  }

  .gameItem {
    width: 110px;
    height: 30px;

    .newBadge {
      border-top-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
}
