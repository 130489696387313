.sideBarRegionsContainerWrapper {
  box-shadow: 4px 4px 4px 0 #00000040;
  .sideBarOverlay {
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .sideBarRegionsContainer {
    &.slideRight {
      top: 0;
      animation: slideRight 0.5s ease forwards;
    }

    &.slideLeft {
      top: 0;
      animation: slideLeft 0.5s ease forwards;
    }

    @keyframes slideRight {
      to {
        opacity: 1;
        transform: translateX(0); // Start from left: 0 (visible)
      }
      from {
        opacity: 0;
        transform: translateX(-100%); // Move off-screen to the left
      }
    }

    @keyframes slideLeft {
      to {
        opacity: 0;
        transform: translateX(-100%); // Start off-screen (left)
      }
      from {
        opacity: 1;
        transform: translateX(0); // Move to the left: 0 position (visible)
      }
    }
  }
}
