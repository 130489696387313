.sportFiltersContainerWrapper {
  .matchItem {
    padding-left: 15px;
    padding-right: 15px;
  }
  &.desktop {
    box-shadow: 0 4px 4px 0 #00000040;
  }
  .sportFiltersSwiper {
    &.desktop {
      min-width: 100px;
      //margin-bottom: 10px;
      //box-shadow: 0 4px 4px 0 #00000040;
      &.wrapperBorders {
        //max-width: 100%;
        //border-radius: 25px;
        width: fit-content !important;
        //border: 1px solid var(--appYellow);
      }
      .swiper-wrapper {
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
      .wrapperBorders {
        width: fit-content;
        //border-radius: 25px;
        //border: 1px solid var(--appYellow);
      }
    }
    &.mobile {
      margin-left: 0;
      max-width: 100%;
      width: fit-content;
      //border-radius: 25px;
      padding: 0 !important;
      //border: 1px solid var(--appYellow);
      .swiper-wrapper {
        //display: flex;
        //align-items: center;
        //justify-content: center;
      }
      .wrapperBorders {
        gap: 5px;
        width: fit-content;
        //border-radius: 17px;
        //border: 1px solid var(--appYellow);
      }
    }

    .swiper-slide {
      height: auto;
      width: fit-content;
      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .gameSearchContainer {
    height: 35px;
    min-width: 200px;
    border-radius: 5px;
  }
}
