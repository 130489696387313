.button {
  width: 100%;
  border-width: 0;
  border-radius: 4px;
  position: relative;
  font-family: inherit;
  transition: opacity 500ms;

  &.flat {
    border-radius: 0 !important;
  }

  &.flatTop {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  &.flatBottom {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.smallSize {
    padding: 5px !important;
  }

  &.button_disabled {
    opacity: 0.4;
  }

  &.loading .buttonTitle,
  &.loading span {
    opacity: 0;
    visibility: hidden;
  }

  .buttonTitle {
    font-weight: 700;
  }

  &.loading::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    width: 16px;
    height: 16px;
    margin: auto;
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    animation: button-loading-spinner 1s ease infinite;
  }

  &.active_background_2 {
    color: var(--appBlue);
    background: var(--appYellow);
  }

  &.active_background_9 {
    color: var(--appBlue);
    background: var(--appYellow);
  }

  &.active_background_6 {
    color: var(--appBlue);
    background: var(--appYellow);
    padding: 8px 15px !important;
  }

  &.active_background_7 {
    color: var(--appBlue);
    background: var(--appYellow);
  }

  &.active_background_8 {
    font-weight: normal;
    color: var(--appBlue);
    background: var(--appYellow);
    border: 1px solid var(--semiDarkenGray);
  }

  &.active_background_14 {
    font-weight: normal;
    color: var(--appBlue);
    background: var(--appYellow);
    border: 1px solid var(--appYellow);
  }

  &.active_background_10 {
    color: var(--white);
    background: var(--appBlue);
  }

  &.active_background_11 {
    color: var(--appBlue);
    background: var(--lightPurple);
    border: 1px solid var(--semiDarkenGray);
  }

  &.active_background_12 {
    color: var(--white);
    background: var(--appYellow);
  }

  &.active_background_15 {
    font-weight: normal;
    color: var(--black);
    background: var(--appYellow);
    border: 1px solid var(--semiDarkenGray);
  }

  &.active_background_17 {
    color: #022751;
    border-radius: 20px;
    background: var(--appYellow);
    padding: 8px 15px !important;
    border: 1px solid var(--transparent);
  }

  &.active_background_19 {
    font-weight: 600;
    color: var(--appBlue);
    background: var(--appYellow);
  }

  &.background_1 {
    color: var(--appBlue);
    background: var(--appYellow);
  }

  &.background_2 {
    color: var(--white);
    background: var(--appBlue);
  }

  &.background_3 {
    color: var(--white);
    background: var(--lightGreen);
  }

  &.background_4 {
    color: var(--white);
    background: var(--darkGreen);
  }

  &.background_5 {
    color: var(--white);
    background: var(--appRed);
  }

  &.background_6 {
    color: var(--appBlue);
    background: var(--white);
    padding: 8px 15px !important;
  }

  &.background_7 {
    color: var(--appBlue);
    background: var(--white);
  }

  &.background_8 {
    color: var(--black);
    font-weight: normal;
    background: var(--lightGray);
    border: 1px solid var(--semiDarkenGray);
  }

  &.background_9 {
    color: var(--white);
    background: var(--darkenBlue);
  }

  &.background_10 {
    color: var(--appBlue);
    background: var(--white);
  }

  &.background_11 {
    color: var(--gray);
    background: var(--lightGray);
    border: 1px solid var(--semiDarkenGray);
  }

  &.background_12 {
    color: var(--appYellow);
    background: var(--appBlue);
  }

  &.background_13 {
    color: var(--white);
    background: var(--gray);
  }

  &.background_14 {
    color: var(--black);
    font-weight: normal;
    background: var(--lightGray);
    border: 1px solid var(--semiDarkenGray);
  }

  &.background_15 {
    color: var(--black);
    font-weight: normal;
    background: var(--lightGray);
    border: 1px solid var(--semiDarkenGray);
  }

  &.background_16 {
    color: white;
    font-size: 12px;
    font-weight: 700;
    background: #fc0e0b;
  }

  &.background_17 {
    color: white;
    font-weight: 600;
    border-radius: 20px;
    background: transparent;
    border: 1px solid white;
    font-size: 12px !important;
    padding: 8px 15px !important;
  }

  &.background_18 {
    color: white;
    font-weight: 500;
    border-radius: 5px;
    background: transparent;
    padding: 10px 12px !important;
    border: 1px solid var(--appYellow);
  }

  &.background_19 {
    color: white;
    font-size: 9px;
    font-weight: 600;
    background: #003069;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}
