.appDatePickerWrapper {
  z-index: 10;
  &_1 {
    max-width: 300px;
  }

  &_3 {
    position: relative;
    .rdrCalendarWrapper {
      left: 0;
      right: 0;
      top: 35px;
      z-index: 10;
      position: absolute;
    }
  }

  .appDatePicker {
    .rdrMonth {
      width: 100% !important;
    }
  }
}
