.appLayout {
  min-height: 100dvh;
  background: var(--darkBlue);
  &.loggedInPadding {
    padding-bottom: 46px;
  }

  &.notLoggedInPadding {
    padding-bottom: 100px;
  }
  .emptyDiv {
    min-width: 150px;
  }

  .contentContainerWrapper {
  }
  .contentWrapper {
    overflow-x: hidden;
    //overflow-y: scroll;
    background: var(--appBlue);
  }

  .content {
    //padding: 5px 10px;
  }

  .appBetSlipWrapper {
    .betSlipDesktopWrapper {
      background: #194478;
    }
  }

  .appBetSlipWrapper_mobile {
    width: 100%;
    height: 100dvh;
    position: fixed;
    align-items: center;

    &.sports {
      top: 40px;
      transition: transform 0.5s ease-in-out;
      &.slide-up {
        transform: translateY(0);
      }
      &.slide-down {
        transform: translateY(110%);
      }
    }

    &.casino {
      top: 40px;
      transition: transform 0.5s ease-in-out;
      &.slide-left {
        transform: translateX(0);
      }
      &.slide-right {
        transform: translateX(110%);
      }
    }
  }

  .betslipToggleButton_close {
    left: 0;
    z-index: 105;
    border-top-right-radius: 65px;
    border-bottom-right-radius: 65px;
    top: calc(50% - 110px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 27px;
    height: 54px;
    border: 1px solid rgba(white, 0.2);
    border-left: 0;
    box-shadow: 0 0.2rem 1.9rem 0 rgba(0, 0, 0, 0.5);
    background-color: rgba(white, 0.05);
    backdrop-filter: blur(1px);
  }
  .appBetSlipWrapper_desktop {
    width: 310px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border: 1px solid var(--appYellow);
    //height: calc(100vh - 175px);
  }
}
