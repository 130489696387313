.specificGameContainer {
  .gamesWrapper {
    .infoHeader {
      padding: 7px 10px;
      //border-radius: 5px;
      background-color: var(--appYellow);

      .sportIcon {
        margin-right: 5px;
        margin-left: 10px;
      }

      .line {
        width: 1px;
        opacity: 0.2;
        height: 11px;
        margin-left: 9px;
        margin-right: 13px;
      }

      .competitionName {
        margin-left: 7px;
      }
    }

    .statsWrapper {
      padding: 2px;
      border-radius: 13px;
      border: 1px solid var(--appYellow);

      .statsItem {
        width: 100%;
        height: 22px;
        border-radius: 11px;
      }
    }

    .transactionContainer {
      //margin-bottom: 20px;
    }

    .select {
      height: 35px;
      max-width: 140px;

      .selectedTitle {
        max-width: 90px;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        text-overflow: ellipsis;
      }

      .dropdown-content {
        .item {
          span {
            max-width: 110px;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.mobile {
  }

  &.loggedOutPadding {
    //padding-bottom: 99px;
  }

  &.loggedInPadding {
    //padding-bottom: 46px;
  }

  .mobile {
    //top: 0;
    //position: sticky;
  }

  .desktop {
  }

  .headerContainer {
    .backIcon {
      transform: rotate(-90deg);
    }

    .line {
      width: 1px;
      height: 40px;
      background-color: var(--lightBlue);
    }
  }

  .filterItemContainer {
  }

  .homeSportsSwiper {
    .swiper-button-prev::after,
    .swiper-button-next::after {
      font-size: 10px;
      font-weight: bold;
      color: var(--white);
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: var(--appYellow);
    }
  }

  .eventListItemContainer {
    padding: 3px 5px;
    border-radius: 5px;
    background-color: #003069;

    &.selected {
      background-color: var(--appYellow);
    }

    .oddChangeIcon {
      right: 3px;
      &.increased {
        //bottom: 0;
        //transform: rotate(135deg);
      }
      &.decreased {
        //top: 0;
        //transform: rotate(45deg);
      }
    }
  }

  .marketContainerRadius {
    padding: 5px 3px;
    border-radius: 5px;
    background-color: #e6eaee;

    .marketsWrapper {
    }
  }

  .statsTextContainer {
    width: 20px;
    border-radius: 5px;
    background-color: var(--black);
  }
}

.headerContainer {
  .teamName {
    //line-clamp: 2;
    //overflow: hidden;
    min-height: 30px;
    //display: -webkit-box;
    //-webkit-line-clamp: 2; /* number of lines to show */
    //-webkit-box-orient: vertical;
  }
}
