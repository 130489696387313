.introContainer {
  top: 0;
  left: 0;
  opacity: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 2147483641;
  align-items: center;
  pointer-events: auto;
  justify-content: center;
  background: var(--appBlue);
  transition:
    opacity 0.3s ease-in-out,
    pointer-events 0s ease 0.3s;

  &.hideIntro {
    opacity: 0;
    pointer-events: none;
  }

  .logo {
    height: 80px;
  }
}
