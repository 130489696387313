.fixedMarketsFiltersContainer {
  .fixedMarketsFiltersSwiper {
    .swiper-slide {
      width: fit-content;
    }
  }
  .selectContainer {
    &.mobile {
      height: 32px !important;
    }
    &.desktop {
      height: 42px !important;
    }
  }
}
