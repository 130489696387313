.fixedCompetitionsSwiper {
  .competitionIcon {
    max-width: 50px;
    object-fit: contain;
  }

  .swiper-slide {
    width: fit-content;
  }
}
