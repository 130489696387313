.appMobileBottomTab {
  .item {
    background: var(--darkBlue);
  }

  .signInButtonsContainer {
    background: var(--darkBlue);

    .buttonItem {
      border-radius: 10px;
    }
  }

  .betSlipCounter {
    top: -5px;
    right: -5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--appYellow);
    border: 1px solid var(--appBlue);
  }

  .activeBorders {
    border-radius: 5px;
  }
}
